import React from 'react';
import Layout from '../components/Layout';
import SimpleHeaderModule from '../components/SimpleHeaderModule';

export default () => (
  <Layout pageTitle="Pricing" description="Info on Miter's plans and pricing">
    <SimpleHeaderModule title="Pricing">
      Miter does not currently offer any paid services—our product offering is free for anyone to use, and we hope
      you'll pay us in feedback. That will change with time, though: we expect always to have some free offering, but to
      charge for products and services beyond that.
    </SimpleHeaderModule>
  </Layout>
);
